<template>
  <div style="padding: 3rem 1.5rem;height: 100%;background: white;display: flex;justify-content: center">
    <div style="width:100%;padding-top: 5rem" v-if="ApplyStatus==='pending'">
      <div style="font-size: 1.75rem;text-align: center;margin-bottom: 2rem">融资申请</div>
      <el-form ref="Apply" :rules="ApplyRules" :model="ApplyFormData" label-position="top" label-width="5rem" style="width: 100%" class="apply_form">
        <el-form-item prop="name">
          <template slot="label">
            <span class="label_login">姓名</span>
          </template>
          <div class="flex-row">
            <el-input class="mobile_input" placeholder="请输入您的姓名" v-model="ApplyFormData.name" ></el-input>
          </div>
        </el-form-item>
        <el-form-item prop="mobile">
          <template slot="label">
            <span class="label_login">手机号</span>
          </template>
          <el-input class="mobile_input" placeholder="请输入您的手机号" v-model="ApplyFormData.mobile" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="submit_Apply btn-color main_btn4" style="margin-top: 0.75rem" @click="ApplySubmit">立即申请</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 9rem" v-if="ApplyStatus==='success'">
        <div class="flex-col items-center justify-center">
          <img
              alt="申请成功"
              style="height: 4.375rem"
              :src="require('@/assets/succeed.png')"
          />
          <span style="font-size: 1.75rem;color: #EDA558;margin: 1.5rem 0">申请成功</span>
<!--        <span style="font-size: 0.875rem;color: #999">客服人员将第一时间联系您，请留意客服电话</span>-->
<!--          <span style="font-size: 0.875rem;color: #999;">账号注册成功，请使用PC进入麦嘉跨境金融官方网站https://www.makafin.com进行相关操作</span>-->
                    <span style="font-size: 0.875rem;color: #999;">工作人员马上就会联系您，请保持手机畅通</span>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Apply",
  data(){
    var  validatemobile = (rule, value, callback) => {
      if(!(/^1[3456789]\d{9}$/.test(value))){
        callback(new Error('请输入正确的手机号码格式'));
      }
      callback()
    }
    return {
      //申请状态
      ApplyStatus:'pending',
      //推荐人信息
      PData:{
        pid:'',
        makafin_source:'1',
      },
      //申请信息
      ApplyFormData:{
        name:'',
        mobile:'',
      },
      //申请数据校验
      ApplyRules:{
        name:[
          { required: true, message: '请输入姓名', trigger: 'blur'}
        ],
        mobile:[
          { required: true, message: '请输入手机号', trigger: 'blur'},
          {validator:validatemobile,trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {

  },
  methods:{
    //立即申请
    ApplySubmit(){
      let query=this.$utils.CacheSessionGet('query') || this.PData;
      this.$refs['Apply'].validate((valid) => {
        if (valid) {
          this.$api.post('v1/api/makafin/base_account/registration', {...this.ApplyFormData,...query},
              res=>{
                this.ApplyStatus='success'
              })
        }
      });
    },
  },

}
</script>
<style>
.apply_form  .el-form-item__label{
  font-size: 1.125rem;
  padding:0 0  0.8rem!important;
}
.apply_form .el-form-item{
  margin-bottom: 2rem!important;
}
.apply_form  .mobile_input .el-input__inner {
  font-size: 0.875rem;
  height: 3.5rem;
}
.apply_form .el-form-item__error{
  font-size: 1rem;
}
</style>
<style scoped>
.label_login{
  font-size: 1.125rem;
  color: #333;
}
.submit_Apply{
  width: 100%;
  font-size: 1.125rem;
  line-height: 3.5;
  color: #333333;
  border-radius: 0.5rem;
}
.btn-color{
    background: #FFBA56;
}
</style>